import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Button from '~/components/Button';
import Layout from '~/components/Layout';

import requireUser, { UserProps } from '../utils/requireUser';

const ProfilePage: FC<PageProps & UserProps> = ({ location, user }) => (
  <Layout pathname={location.pathname} showNavigation={false}>
    <Button to="/espaces/create">Créer un espace de travail</Button>
  </Layout>
);

export default requireUser(ProfilePage);
